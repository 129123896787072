/*
 * @Author: your name
 * @Date: 2021-09-04 10:37:27
 * @LastEditTime: 2021-09-16 15:59:17
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \全过程\prcoress\src\api\main\deptMana\index.js
 */
import { axios } from '@/utils/request';
/**
 * 查询树类别列表
 */
export function getListTree(parameter) {
  return axios({
    url: '/sysOrg/tree',
    method: 'get',
    params: parameter
  });
}
export function getList(parameter) {
  return axios({
    url: '/sysOrg/page',
    method: 'get',
    params: parameter
  });
}
export function addOrEdit(parameter) {
  return axios({
    url: '/sysOrg/addOrEdit',
    method: 'post',
    data: parameter
  });
}
export function getEdit(parameter) {
  return axios({
    url: '/sysOrg/detail',
    method: 'get',
    params: parameter
  });
}
export function getOrgTree(parameter) {
  return axios({
    url: '/sysOrg/tree',
    method: 'get',
    params: parameter
  });
}
export function postDelete(parameter) {
  return axios({
    url: '/sysOrg/delete',
    method: 'post',
    data: parameter
  });
}
