<template>
  <div>
    <div class="title">部门管理</div>
    <div class="content">
      <div class="tree">
        <div class="tree-title">目录结构</div>
        <el-tree :data="orgTree" :props="defaultProps" @node-click="handleNodeClick"></el-tree>
      </div>
      <div class="right-table">
        <div class="edit-form">
          <el-form :inline="true" :model="typeForm" class="demo-form-inline">
            <el-form-item>
              <el-input v-model="typeForm.name" placeholder="请输入部门名称"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="addType">查询</el-button>
              <el-button @click="resetForm">重置</el-button>
            </el-form-item>
          </el-form>
        </div>
        <div class="top-search">
          <el-button type="primary" icon="el-icon-plus" @click="openDialog">新建</el-button>
          <div>
            <el-button size="small" :loading="exportLoading" :disabled="isSelect" @click="handleExport">导出</el-button>
            <el-button type="danger" :disabled="isSelect" @click="handleDelete(selectedIdList)">批量删除</el-button>
          </div>
        </div>
        <el-table
          :data="tableData"
          stripe
          size="medium"
          v-loading="loading"
          style="width: 100%; margin-top: 26px"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" align="center" width="60"></el-table-column>
          <el-table-column prop="name" label="组织名称" align="center" width="180"></el-table-column>
          <el-table-column prop="pName" label="上级组织" align="center" width="180"></el-table-column>
          <el-table-column prop="sort" align="center" label="排序"></el-table-column>
          <el-table-column prop="remark" align="center" label="备注"></el-table-column>
          <el-table-column fixed="right" label="操作" align="center" width="260">
            <template slot-scope="{ row }">
              <div class="comm-operation">
                <span class="edit-btn mr-16" @click="handleCheck(row)">修改</span>
                <span class="delete-btn" @click="handleDelete(row.id)">删除</span>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination">
          <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :page-sizes="[10, 20, 30, 50]"
            layout="total, prev, pager, next, sizes"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 新增/编辑 对话框 -->
    <el-dialog
      :title="titleName"
      :visible.sync="dialogVisible"
      width="560px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-form ref="paramsFormRef" label-width="80px" :model="paramsForm" :rules="rules">
        <el-form-item label="组织名称" prop="name">
          <el-input v-model.trim="paramsForm.name" maxlength="50"></el-input>
        </el-form-item>
        <el-form-item label="唯一编码" prop="code">
          <el-input v-model.trim="paramsForm.code" maxlength="50"></el-input>
        </el-form-item>
        <el-form-item>
          <span slot="label">上级组织</span>
          <tree-select
            :data="treeData"
            :defaultProps="treeProps"
            :nodeKey="nodeKey"
            :width="415"
            :checkedKeys="defaultCheckedKeys"
            @popoverHide="popoverHide"
          ></tree-select>
        </el-form-item>
        <el-form-item label="排序">
          <el-input v-model.trim="paramsForm.sort" maxlength="10"></el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input
            v-model="paramsForm.remark"
            type="textarea"
            maxlength="200"
            :rows="5"
            resize="none"
            show-word-limit
            placeholder="请输入"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="onClose">取消</el-button>
        <el-button type="primary" :loading="submitLoading" @click="onSubmit">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import TreeSelect from '@/components/treeSelect/index.vue';
import { exportMethods } from '@/utils/exportMethods.js';

import { getListTree, getList, addOrEdit, getEdit, getOrgTree, postDelete } from '@/api/main/system/deptMana';
export default {
  components: {
    TreeSelect
  },
  data() {
    return {
      total: 0,
      typeForm: {
        name: '',
        pid: '',
        pageNo: 1,
        pageSize: 10
      },
      titleName: '新增部门',
      loading: false,
      submitLoading: false,
      paramsForm: {
        name: '',
        code: '',
        pid: '',
        id: '',
        sort: '',
        remark: ''
      },
      rules: {
        name: [{ required: true, message: '请输入组织名称', trigger: 'blur' }],
        code: [{ required: true, message: '请输入唯一编码', trigger: 'blur' }]
      },
      treeData: [],
      exportLoading: false,

      treeProps: {
        children: 'children',
        label: 'title'
      },
      nodeKey: 'id',
      defaultCheckedKeys: [],
      dialogVisible: false,
      isSelect: true,
      tableData: [],
      selectedIdList: [],
      orgTree: [],
      defaultProps: {
        children: 'children',
        label: 'title'
      }
    };
  },
  created() {
    this.getList();
    this.getOrgTreeData();
  },
  methods: {
    // 获取菜单list
    getList() {
      this.loading = true;
      getList(this.typeForm)
        .then(res => {
          if (res.code !== 200) {
            return;
          }
          this.total = res.data.totalRows;
          this.tableData = res.data.rows;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    addType() {
      this.typeForm.pageNo = 1;
      this.getList();
    },
    resetForm() {
      this.typeForm.pageNo = 1;
      this.typeForm.pageSize = 10;
      this.typeForm.name = '';
      this.typeForm.pid = '';
      this.getList();
    },
    handleCheck(row) {
      this.getMenuTreeData();
      getEdit({ id: row.id }).then(res => {
        if (res.code !== 200) {
          return;
        }
        this.titleName = '编辑部门';
        this.dialogVisible = true;
        this.paramsForm = res.data;
      });
    },
    // 分页
    handleSizeChange(val) {
      this.typeForm.pageNo = 1;
      this.typeForm.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.typeForm.pageNo = val;
      this.getList();
    },
    handleNodeClick(data) {
      console.log(data);
      this.typeForm.pid = data.id;
      this.typeForm.pageNo = 1;
      this.getList();
    },
    // 选择父级
    popoverHide(checkedIds, checkedData) {
      this.checkedData = checkedData;
      this.paramsForm.pid = checkedData.id;
    },
    // 获取父级节点
    getMenuTreeData(pid) {
      getListTree().then(res => {
        this.treeData = res.data;
        console.log(res.data);
        this.defaultCheckedKeys = [Number(pid)];
      });
    },
    onClose() {
      this.dialogVisible = false;
      this.$refs.paramsFormRef.resetFields();
      this.paramsForm = this.$options.data().paramsForm;

      this.checkedData = null;
    },
    onSubmit() {
      this.$refs.paramsFormRef.validate(valid => {
        if (!valid) return;
        this.submitLoading = true;
        if (this.paramsForm.pid === '') {
          this.paramsForm.pid = '0';
        }
        addOrEdit(this.paramsForm)
          .then(res => {
            const { id } = this.paramsForm;
            this.$message.success(id ? '编辑部门成功！' : '新增部门成功！');
            this.onClose();
            this.getList();
          })
          .finally(() => {
            this.submitLoading = false;
          });
      });
    },
    handleSelectionChange(val) {
      this.selectedIdList = val.map(item => item.id);
      this.isSelect = !this.selectedIdList.length;
    },
    openDialog() {
      this.dialogVisible = true;
      this.getMenuTreeData();
    },
    // 获取部门树
    getOrgTreeData() {
      getOrgTree({}).then(res => {
        this.orgTree = res.data;
        this.formatteTreeData(this.orgTree);
      });
    },
    // 处理 orgTree
    formatteTreeData(arr = []) {
      arr.forEach(item => {
        if (item.children && item.children.length > 0) {
          this.formatteTreeData(item.children);
        } else if (item.children && item.children.length === 0) {
          delete item.children;
        }
      });
    },
    // 导出
    handleExport() {
      this.exportLoading = true;
      exportMethods('/sysOrg/export', { idList: this.selectedIdList }).finally(() => {
        this.exportLoading = false;
      });
    },
    // 点击删除
    handleDelete(data) {
      let ids;
      let message = '';
      if (typeof data === 'string') {
        ids = data;
        message = '删除部门成功！';
      } else if (Array.isArray(data)) {
        ids = data.join(',');
        message = '批量删除部门成功！';
      }

      this.$confirm('是否确认删除所选部门, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          return postDelete({ ids });
        })
        .then(res => {
          this.$message.success(message);
          this.getList();
        })
        .catch(err => {
          if (err) return; // axios 统一处理了错误提示
        });
    }
  }
};
</script>
<style lang="less" scoped>
.title {
  padding: 0 16px 12px;
  font-size: 16px;
  font-weight: bold;
  border-bottom: 1px solid #e8e8e8;
}
.edit-form {
  border-bottom: 1px solid #e8e8e8;
}
.content {
  padding: 16px;
  .right-table {
    overflow: hidden;
    .top-search {
      margin-top: 12px;
      display: flex;
      justify-content: space-between;
    }
  }
}
/deep/.el-tree {
  .el-tree-node__content {
    display: block !important;
  }
  .el-tree-node__children {
    overflow: visible !important;
  }
}
.tree {
  width: 180px;
  float: left;
  border: 1px solid #dcdfe7;
  margin-right: 16px;
  padding: 12px;
  //height: 300px;
  min-height: calc(100vh - 300px);
  overflow: hidden;
  overflow-x: auto;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background: #d8d8d8;
    border-radius: 3px;
  }
  &::-webkit-scrollbar-track-piece {
    background: transparent;
  }
  .tree-title {
    padding-bottom: 26px;
  }
}
.pagination {
  height: 70px;
  background: #fff;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
/deep/.table-scope {
  .el-switch__label--left {
    position: relative;
    left: 58px;
    color: #fff;
    z-index: -1111;
    font-size: 12px;
  }

  .el-switch__core {
    width: 52px !important;
  }

  .el-switch__label--right {
    position: relative;
    right: 57px;
    color: #fff;
    z-index: -1111;
    font-size: 12px;
  }

  .el-switch__label--right.is-active {
    z-index: 3;
    color: #fff !important;
  }

  .el-switch__label--left.is-active {
    z-index: 3;
    color: #fff !important;
  }
}
</style>
